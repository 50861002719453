import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import DoneIcon from "@mui/icons-material/Done";
import { ActionButton } from "../UI";
import ValidateDowngradeDialog from "./ValidateDowngradeDialog";
import { AccountContext } from "../../features/user/Account";
import { shouldValidateAction } from "../../utils/utils.ts";

function PricingPlan({
    id,
    title,
    priceHTML,
    features,
    selected,
    primaryAction,
    preSelected,
}) {
    const { userType } = useContext(AccountContext);
    const [openValidateDowngradeDialog, setOpenValidateDowngradeDialog] =
        useState(false);

    const handleActionButtonClick = () => {
        if (shouldValidateAction(userType, id)) {
            setOpenValidateDowngradeDialog(true);
        } else {
            primaryAction.onClick(id);
        }
    };

    let className = "PricingPlan";
    if (selected) {
        className += " Selected";
    }
    return (
        <div className={className} data-testid={id}>
            <ValidateDowngradeDialog
                open={openValidateDowngradeDialog}
                onClose={() => {
                    setOpenValidateDowngradeDialog(false);
                }}
                onValidateClick={() => {
                    primaryAction.onClick(id);
                }}
            />
            {selected && <div className="Badge">Current plan</div>}
            <h2 className="Title">{title}</h2>
            <div className="PriceContainer">{priceHTML}</div>
            <div className="Features">
                {features.map((feature, i) => (
                    <div key={i} className="Feature">
                        <div className="Icon">
                            <DoneIcon />
                        </div>
                        <div className="Text">{feature}</div>
                    </div>
                ))}
            </div>

            <div>
                <ActionButton
                    id={`upgradeButton-${id}`}
                    onClick={handleActionButtonClick}
                    variant="contained"
                    disabled={selected}
                    className={preSelected ? "Preselected" : ""}
                >
                    {selected ? "Current" : primaryAction.title}
                </ActionButton>
            </div>
        </div>
    );
}

PricingPlan.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    priceHTML: PropTypes.node.isRequired,
    features: PropTypes.arrayOf(PropTypes.string).isRequired,
    selected: PropTypes.bool,
    primaryAction: PropTypes.shape({
        title: PropTypes.string,
        onClick: PropTypes.func,
    }).isRequired,
    preSelected: PropTypes.bool.isRequired,
};

PricingPlan.defaultProps = {
    selected: false,
};

export default PricingPlan;
