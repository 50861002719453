import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { DialogTitle } from "@mui/material";

import StyledConfirmSubscriptionCancellationDialog from "../../styles/components/Subscription/ConfirmSubscriptionCancellationDialog.Styled";
import SubscriptionContentAndActionsRenderer from "./SubscriptionContentAndActionsRenderer";

function ConfirmSubscriptionCancellationDialog({
    open,
    onClose,
    onSubmitCallback,
}) {
    const [openDialog, setOpenDialog] = useState(open);

    useEffect(() => {
        setOpenDialog(open);
    }, [open]);

    return (
        <StyledConfirmSubscriptionCancellationDialog
            open={openDialog}
            onClose={onClose}
            maxWidth="xs"
            data-testid="confirmCancelSubscriptionDialog"
            fullWidth
        >
            <DialogTitle>Cancel subscription</DialogTitle>

            <SubscriptionContentAndActionsRenderer
                open={open}
                onClose={onClose}
                onSubmitCallback={onSubmitCallback}
            />
        </StyledConfirmSubscriptionCancellationDialog>
    );
}

ConfirmSubscriptionCancellationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmitCallback: PropTypes.func,
};

ConfirmSubscriptionCancellationDialog.defaultProps = {
    onSubmitCallback: () => {},
};

export default ConfirmSubscriptionCancellationDialog;
