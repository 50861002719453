import React, { useState, useEffect, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Block, BlockContent, BlockHeader } from "../../components/Block";
import ploomberAPI from "../../services/ploomberAPI.ts";
import { AppContext } from "../../context/AppContext";
import { checkIfEKSDeployment, parseErrorMessage } from "../../utils/utils.ts";
import { ApplicationSettingsContext } from "./ApplicationSettingsContext";
import SetCustomDomainController from "./controllers/customDomainConroller/SetCustomDomainController";
import SetProjectLabelsController from "./controllers/SetProjectLabelsController";

function ApplicationSettings() {
    const [disableSettings, setDisableSettings] = useState(true);
    // do not show label editing until we have the job labels
    const [loadingLabels, setLoadingLabels] = useState(true);

    const { handleUpdateProjectLabels } = useContext(
        ApplicationSettingsContext
    );
    const { updateSnackbarStatus } = useContext(AppContext);
    const { jobId } = useParams();
    const [projectId, setProjectId] = useState();
    const [jobLabels, setJobLabels] = useState([]);

    useEffect(() => {
        ploomberAPI
            .getJob(jobId)
            .then((job) => {
                const isEKSDeployment = checkIfEKSDeployment(job);
                if (isEKSDeployment) {
                    setDisableSettings(false);
                } else {
                    setDisableSettings(job.status !== "running");
                }

                setProjectId(job.projectId);
                setJobLabels(job.labels);
                setLoadingLabels(false);
            })
            .catch((err) => {
                updateSnackbarStatus({
                    severity: "error",
                    message: parseErrorMessage(err),
                });
                setLoadingLabels(false);
            });
    }, []);

    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const applicationContextValue = {
        projectId,
        handleUpdateProjectLabels,
    };

    return (
        <ApplicationSettingsContext.Provider value={applicationContextValue}>
            <div className="ApplicationSettings">
                <Block id="overview" open={Boolean(true)}>
                    <BlockHeader
                        expandable={false}
                        title="Overview"
                        description=""
                    />
                    <BlockContent>
                        <SetProjectLabelsController
                            labels={jobLabels}
                            onChange={(newLabels) => {
                                handleUpdateProjectLabels(
                                    projectId,
                                    newLabels,
                                    setJobLabels,
                                    updateSnackbarStatus
                                );
                            }}
                            disabled={loadingLabels}
                        />
                    </BlockContent>
                </Block>
                <Block id="customDomainSettings" open={Boolean(true)}>
                    <BlockHeader
                        expandable={false}
                        title="Custom domains"
                        description="Use your domain to host this application"
                    />
                    <BlockContent>
                        <SetCustomDomainController disabled={disableSettings} />
                    </BlockContent>
                </Block>
            </div>
        </ApplicationSettingsContext.Provider>
    );
}

ApplicationSettings.propTypes = {};

ApplicationSettings.defaultProps = {};

export default ApplicationSettings;
