import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    Box,
    Card,
    DialogTitle,
    CardActionArea,
    TextField,
    DialogActions,
    DialogContent,
    Typography,
} from "@mui/material";

import { AccountContext } from "../../user/Account";
import { AppContext } from "../../../context/AppContext";
import { ActionButton } from "../../../components/UI";
import ploomberAPI from "../../../services/ploomberAPI.ts";
import telemetry from "../../../services/telemetry.ts";
import HelperTooltip from "../../../components/HelperTooltip";
import {
    StyledAddCreditsDialog,
    StyledCreditsCard,
} from "../../../styles/components/Billing/AddCreditsDialog.Styled";

export async function handleStripeSubmit(amount, credits, newTab = false) {
    let paymentUrl;
    try {
        paymentUrl = await ploomberAPI.createAddCreditsLink(amount, credits);
    } catch (error) {
        return;
    }

    if (newTab) {
        window.open(paymentUrl.payment_url, "_blank").focus();
    } else {
        window.location.href = paymentUrl.payment_url;
    }
}

function AddCreditsDialog({ open, onClose }) {
    const { setCreditInfo, navigate, updateSnackbarStatus } =
        useContext(AppContext);
    const { getUserConfiguration } = useContext(AccountContext);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [selectedCredits, setSelectedCredits] = useState(null);
    const [currentCredits, setCurrentCredits] = useState(
        localStorage.getItem("availableCredits")
    );

    const creditPackages = getUserConfiguration("creditPackages");

    useEffect(() => {
        let intervalId;

        const updateUserCredits = async () => {
            try {
                const response = await ploomberAPI.getUserCredits();
                const newCredits =
                    response.allotted_credits +
                    response.available_purchased_credits;
                setCreditInfo({
                    ...response,
                    total_available_credits: newCredits,
                    loading: false,
                });

                const url = new URL(window.location);

                // User pressed "cancel" during checkout
                if (!url.searchParams.has("verifyCredits")) {
                    return;
                }

                // User completed checkout
                if (newCredits > currentCredits) {
                    // Payment verification passed
                    updateSnackbarStatus({
                        message: `Successfully added credits`,
                    });
                    setCurrentCredits(newCredits);

                    telemetry.log("Add credits payment Verified For User", {
                        includeUserDetails: true,
                    });
                } else {
                    // Payment verification failed
                    updateSnackbarStatus({
                        message: `Payment was unsuccessful. Your payment method was not charged.\nFor more details, please contact support: contact@ploomber.io`,
                        severity: "error",
                        duration: 20000, // Show snackbar for 20 seconds
                    });
                    telemetry.log(
                        "Unable to verify add credits payment For User",
                        {
                            includeUserDetails: true,
                        }
                    );
                }

                navigate("/applications");
                clearInterval(intervalId);
            } catch (error) {
                updateSnackbarStatus({
                    message: `Error fetching user credits: ${error}`,
                    severity: "error",
                });
                clearInterval(intervalId);
            }
        };

        const searchParams = new URLSearchParams(window.location.search);
        const verifyCredits = searchParams.get("verifyCredits");

        if (verifyCredits) {
            intervalId = setInterval(updateUserCredits, 1000);
        }
    }, []);

    return (
        <StyledAddCreditsDialog
            open={open}
            onClose={onClose}
            data-testid="addCreditsDialog"
        >
            <DialogTitle>Add Credits</DialogTitle>

            <DialogContent>
                <span className="Subtitle">
                    Select your package
                    <HelperTooltip text="Select a discounted credits package and get additional resource hours" />
                </span>
                <Box className="PackageContainer">
                    {Object.entries(creditPackages).map(
                        ([credits, packageInfo], index) => (
                            <StyledCreditsCard
                                key={index}
                                data-testid={`credits-package-${credits}`}
                                selectedCard={
                                    selectedPackage === packageInfo.price
                                }
                                onClick={() => {
                                    setSelectedPackage(packageInfo.price);
                                    setSelectedCredits(credits);
                                }}
                            >
                                <CardActionArea>
                                    <Box sx={{ height: "1rem" }}>
                                        <Typography
                                            variant="h5"
                                            align="center"
                                            className="OriginalPrice"
                                        >
                                            ${credits}
                                        </Typography>
                                    </Box>
                                    <Box className="PackagePriceContainer">
                                        <Typography
                                            variant="body2"
                                            align="center"
                                            className="PackagePrice"
                                        >
                                            ${packageInfo.price}
                                        </Typography>
                                    </Box>
                                </CardActionArea>
                            </StyledCreditsCard>
                        )
                    )}
                </Box>
            </DialogContent>

            <DialogActions>
                <Box
                    className="Buttons"
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                >
                    <ActionButton onClick={onClose} variant="text">
                        CANCEL
                    </ActionButton>

                    <ActionButton
                        data-testid="checkoutAddCredits"
                        disabled={!selectedPackage}
                        onClick={() =>
                            handleStripeSubmit(selectedPackage, selectedCredits)
                        }
                        variant="contained"
                    >
                        CHECKOUT
                    </ActionButton>
                </Box>
            </DialogActions>
        </StyledAddCreditsDialog>
    );
}

AddCreditsDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default AddCreditsDialog;
