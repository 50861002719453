import {
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogContentText,
    Button,
    TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

function DeleteDialogContentWithConfirmation({
    message,
    confirmationText,
    handleDeleteProject,
    onClose,
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [actionCanBePerform, setActionCanBePerform] = useState(false);
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        setActionCanBePerform(inputValue === confirmationText);
    }, [inputValue, confirmationText]);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        await handleDeleteProject();
        setIsLoading(false);
    };

    return (
        <>
            <DialogContent>
                <DialogContentText component="div" color="#000">
                    <div>{message}</div>
                </DialogContentText>
                <TextField
                    id="outlined-delete-confirm"
                    label={
                        <>
                            Enter{" "}
                            <strong data-testid="project-name-confirm">
                                {confirmationText}
                            </strong>{" "}
                            to confirm
                        </>
                    }
                    required
                    focused
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    color="error"
                    disabled={isLoading || !actionCanBePerform}
                >
                    Delete
                    {isLoading && (
                        <CircularProgress
                            size={24}
                            style={{ marginLeft: "10px" }}
                        />
                    )}
                </Button>
            </DialogActions>
        </>
    );
}

DeleteDialogContentWithConfirmation.propTypes = {
    message: PropTypes.string.isRequired,
    confirmationText: PropTypes.string.isRequired,
    handleDeleteProject: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default DeleteDialogContentWithConfirmation;
