import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import HelperTooltip from "../../../components/HelperTooltip";
import { AccountContext } from "../../user/Account";
import { handleStripeSubmit } from "../../../components/StripePaymentButton";
import { AppContext } from "../../../context/AppContext";

export function RestrictedController({
    children,
    validationFunction,
    featureId,
    onCanAccessChange,
}) {
    const { navigate, updateSnackbarStatus } = useContext(AppContext);
    const { canUserAccessComponent, userType } = useContext(AccountContext);
    const [canAccess, setCanAccess] = useState();

    useEffect(() => {
        if (validationFunction) {
            const response = validationFunction();
            setCanAccess(response);
        } else {
            setCanAccess(canUserAccessComponent(featureId));
        }
    }, [userType]);

    useEffect(() => {
        if (onCanAccessChange) {
            onCanAccessChange(canAccess);
        }
    }, [canAccess, onCanAccessChange]);

    if (canAccess) {
        return children;
    }

    const disableChildren = () => {
        if (!children) return null;

        if (React.isValidElement(children) && "disabled" in children.props) {
            return React.cloneElement(children, { disabled: !canAccess });
        }

        return children;
    };

    return (
        <HelperTooltip
            text={
                <div
                    role="button"
                    onKeyPress={(e) => {}}
                    tabIndex="0"
                    onClick={() => {}}
                    data-testid="restricted-controller-tooltip"
                >
                    <div>This option is not available for your plan.</div>

                    <div>
                        <span
                            style={{
                                textDecoration: "underline",
                            }}
                        >
                            <a
                                href="/"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleStripeSubmit(
                                        updateSnackbarStatus,
                                        "pro"
                                    );
                                }}
                                style={{
                                    textDecoration: "inherit",
                                    color: "inherit",
                                }}
                            >
                                Click here
                            </a>
                        </span>{" "}
                        to upgrade your account.
                    </div>
                </div>
            }
        >
            {disableChildren()}
        </HelperTooltip>
    );
}

RestrictedController.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element,
    ]),
    validationFunction: PropTypes.func,
    featureId: PropTypes.string,
    onCanAccessChange: PropTypes.func,
};

RestrictedController.defaultProps = {
    children: undefined,
    validationFunction: undefined,
    featureId: undefined,
    onCanAccessChange: undefined,
};

function UserValidation(Component) {
    function ComponentWithUserValidation(props) {
        const { getUserConfiguration } = useContext(AccountContext);
        const { featureId, disableUserCheck, toolTipMsg } = props;

        const userAllowedFeatures = getUserConfiguration("allowedFeatures");
        const canUseController = disableUserCheck
            ? true
            : userAllowedFeatures.includes(featureId);

        if (canUseController) {
            return <Component {...props} />;
        }

        const ComponentToRender = (
            <Component {...props} className="DisableComponent" />
        );

        return (
            <div
                style={{
                    display: "inline-flex",
                    flexDirection: "row-reverse",
                }}
                feature-id={featureId}
            >
                <RestrictedController />
                {ComponentToRender}
            </div>
        );
    }

    ComponentWithUserValidation.propTypes = {
        featureId: PropTypes.string,
        disableUserCheck: PropTypes.bool,
        toolTipMsg: PropTypes.string,
    };

    ComponentWithUserValidation.defaultProps = {
        featureId: undefined,
        disableUserCheck: false,
        toolTipMsg: undefined,
    };

    return ComponentWithUserValidation;
}

export default UserValidation;
