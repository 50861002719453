import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogTitle } from "@mui/material";
import ploomberAPI from "../services/ploomberAPI.ts";
import { AppContext } from "../context/AppContext";
import { getAPIVersion, parseErrorMessage } from "../utils/utils.ts";
import DeleteDialogContentWithConfirmation from "./UI/DeleteDialogContentWithConfirmation";

function DeleteProjectDialog({
    projectName,
    projectId,
    open,
    onClose,
    onProjectDeleteComplete,
    isEKSDeployment,
}) {
    const [openDialog, setOpenDialog] = useState(open);
    const [projectJobs, setProjectJobs] = useState([]);
    const { updateSnackbarStatus } = useContext(AppContext);

    useEffect(() => {
        if (openDialog) {
            ploomberAPI
                .getUserProject(projectId)
                .then((project) => setProjectJobs(project.jobs));
        }
    }, [openDialog]);

    useEffect(() => {
        setOpenDialog(open);
    }, [open]);

    const handleDeleteProject = async () => {
        try {
            const apiVersion = getAPIVersion(isEKSDeployment);
            await ploomberAPI.deleteProject(projectId, apiVersion);
            setOpenDialog(false);
            updateSnackbarStatus({
                message: "Project deleted successfully",
            });
        } catch (err) {
            updateSnackbarStatus({
                message: parseErrorMessage(err),
                severity: "error",
            });
        } finally {
            if (onProjectDeleteComplete) {
                onProjectDeleteComplete();
            }
        }
    };

    return (
        <Dialog
            open={openDialog}
            onClose={onClose}
            maxWidth="xs"
            fullWidth
            style={{
                height: "60%",
            }}
            onAuxClick={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
        >
            <DialogTitle
                id="responsive-dialog-title"
                onContextMenu={(e) => e.stopPropagation()}
            >
                Delete <strong>{projectName ?? projectId}</strong>
            </DialogTitle>
            <DeleteDialogContentWithConfirmation
                message="Are you sure you want to delete this project?"
                confirmationText={projectName ?? projectId}
                handleDeleteProject={handleDeleteProject}
                onClose={onClose}
            />
        </Dialog>
    );
}

DeleteProjectDialog.propTypes = {
    projectName: PropTypes.string,
    projectId: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onProjectDeleteComplete: PropTypes.func,
    isEKSDeployment: PropTypes.bool,
};

DeleteProjectDialog.defaultProps = {
    projectName: null,
    onProjectDeleteComplete: () => {},
    isEKSDeployment: false,
};

export default DeleteProjectDialog;
