import React, { useEffect, useState, useContext } from "react";
import { AgGridReact } from "ag-grid-react";
import { Button } from "@mui/material";
import { AppContext } from "../../../context/AppContext";
import DomainsActionsRenderer from "./DomainsActionsRenderer";
import ploomberAPI from "../../../services/ploomberAPI.ts";
import NewCustomDomainDialog from "./NewCustomDomainDialog";
import SettingsGridLayout from "./SettingsGridLayout";
import { AccountContext } from "../../user/Account";
import ControllerLabel from "../../../components/UI/ControllerLabel";
import { RestrictedController } from "../../applications/hoc/userValidation";

const FEATURE_ID = "customDomain";

function CustomDomainsGrid() {
    const { canUserAccessComponent } = useContext(AccountContext);

    const [customDomains, setCustomDomains] = useState([]);
    const { user } = useContext(AppContext);
    const [canUse, setCanUse] = useState(false);
    const [isFetchingUserDomains, setIsFetchingUserDomains] = useState(false);
    const [openNewCustomDomainDialog, setOpenNewCustomDomainDialog] =
        useState(false);

    const initCustomDomains = async () => {
        setIsFetchingUserDomains(true);
        const response = await ploomberAPI.getCustomDomains();
        setCustomDomains(response);
        setIsFetchingUserDomains(false);
    };

    const customDomainsColumnDefs = [
        {
            headerName: "URL",
            field: "name",
            sortable: false,
            filter: false,
            flex: 3,
            resizable: false,
        },
        {
            headerName: "Actions",
            width: 130,
            cellRenderer: DomainsActionsRenderer,
            cellRendererParams: {
                customDomains: customDomains.map((d) => d.name),
                onDomainDeleted: initCustomDomains,
                isFetchingUserDomains,
            },
            pinned: "right",
        },
    ];

    useEffect(() => {
        if (canUse) {
            initCustomDomains();
        }
    }, [canUse]);

    return (
        <>
            <SettingsGridLayout
                id="customDomainsGrid"
                loading={isFetchingUserDomains}
                title="Domains"
                actions={
                    <RestrictedController
                        featureId={FEATURE_ID}
                        onCanAccessChange={setCanUse}
                    >
                        <Button
                            id="newCustomDomainButton"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setOpenNewCustomDomainDialog(true);
                            }}
                            disabled={!canUse || isFetchingUserDomains}
                        >
                            New Domain
                        </Button>
                    </RestrictedController>
                }
            >
                <p>
                    Use your own domain for hosting applications directly or as
                    subdomains
                </p>

                <AgGridReact
                    autoSizePadding={0}
                    domLayout="autoHeight"
                    columnDefs={customDomainsColumnDefs}
                    rowData={customDomains}
                />
            </SettingsGridLayout>

            <NewCustomDomainDialog
                registeredDomains={customDomains}
                open={openNewCustomDomainDialog}
                onClose={() => {
                    setOpenNewCustomDomainDialog(false);
                }}
                onCreate={() => {
                    setOpenNewCustomDomainDialog(false);
                    initCustomDomains();
                }}
            />
        </>
    );
}

export default CustomDomainsGrid;
