import { Card, Dialog } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledAddCreditsDialog = styled(Dialog)(({ theme }) => ({
    height: "100%",
    maxWidth: "sm",

    "& .MuiDialogTitle-root": {
        fontSize: "1.5rem",
        padding: "1.5rem",
        paddingBottom: "1.5rem",
    },
    "& .MuiDialogContent-root": {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    "& .MuiDialogActions-root": {
        padding: "1rem",
        justifyContent: "space-between",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    "& .Subtitle": {
        marginBottom: "1rem",
    },

    "& .Buttons": {
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
    },

    "& .PackageContainer": {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        marginBottom: "2rem",
    },

    "& .PackagePrice": {
        marginTop: "0.5rem",
        fontWeight: "bold",
        fontSize: "1.5rem",
    },

    "& .PackagePriceContainer": {
        height: "2rem",
        marginTop: "2rem",
    },
}));

const StyledCreditsCard = styled(Card)(({ theme, selectedCard }) => ({
    width: "7.5rem",
    padding: "0.6rem",
    margin: "0.6rem",
    backgroundColor: "#fff",
    cursor: "pointer",
    boxShadow: selectedCard ? "0 0 0.5rem #000000" : "0 0.2rem 0.3rem #808080",

    "& .OriginalPrice": {
        color: "grey",
        textDecoration: "line-through",
        textDecorationThickness: "0.1rem",
        fontFamily: "Open Sans",
    },
}));

export { StyledAddCreditsDialog, StyledCreditsCard };
