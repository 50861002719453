import React from "react";
import { Alert, AlertTitle, Link, Box } from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";

function UpdatePaymentMethodNotice() {
    return (
        <Box>
            <Alert severity="info" icon={<CreditCardIcon />}>
                <AlertTitle>Need to update your payment method?</AlertTitle>
                {"Please email us at "}
                <Link
                    href="mailto:contact@ploomber.io"
                    underline="hover"
                    sx={{ fontWeight: "medium", textDecoration: "underline" }}
                >
                    contact@ploomber.io
                </Link>
                {
                    " and we'll send you a secure Stripe link to update your payment information."
                }
            </Alert>
        </Box>
    );
}

export default UpdatePaymentMethodNotice;
