import { formatDistanceToNow } from "date-fns";

import HTTPRequest from "./httpRequest.ts";
import {
    UserLoginResponse,
    UserSession,
    ResourcesInfo,
    Project,
    ProjectJobResponse,
    Job,
    JobResponse,
    JobInfoResponse,
    JobLogsResponse,
    Logs,
    APIKey,
    CustomDomain,
    Subscription,
    ExamplesResponse,
    UserCredits,
    BillingHistoryResponse,
    VerifyUpgradeResponse,
} from "../models/interfaces.ts";
import {
    setAccessToken,
    getAccessToken,
    getRedirectParam,
} from "../utils/utils.ts";

class AsyncLock {
    constructor() {
        this.isLocked = false;
        this.queue = [];
    }

    async acquire() {
        const acquireLock = () =>
            new Promise((resolve) => {
                this.queue.push(resolve);
            });

        if (this.isLocked) {
            await acquireLock();
        } else {
            this.isLocked = true;
        }
    }

    release() {
        if (this.queue.length > 0) {
            const nextResolver = this.queue.shift();
            nextResolver();
        } else {
            this.isLocked = false;
        }
    }
}

const asyncLock = new AsyncLock();

const REFRESH_TOKEN_WHEN_EXPIRED_IN_MINUTES = 5;

function getURL(endpoint: string, apiVersion: string = ""): string {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    return apiVersion
        ? `${baseUrl}/${apiVersion}/${endpoint}`
        : `${baseUrl}/${endpoint}`;
}

const logout = async (retryAttempt = 0): Promise<boolean> => {
    // Call the server to expired the HttpOnly cookie
    const MAX_RETRY_ATTEMPTS = 3;
    const RETRY_DELAY_MS = 1000;
    const delay = (ms: number) =>
        new Promise((resolve) => {
            setTimeout(resolve, ms);
        });

    const url = getURL("users/logout");

    try {
        const response = await HTTPRequest.post(url, {});
        if (response.ok) {
            return true;
        }
        throw new Error("Logout failed");
    } catch (error) {
        if (retryAttempt < MAX_RETRY_ATTEMPTS - 1) {
            console.log(
                `Retrying logout in ${RETRY_DELAY_MS / 1000} seconds...`
            );
            await delay(RETRY_DELAY_MS);
            return logout(retryAttempt + 1);
        }
        console.error("Max retry attempts reached. Logout failed.");
        return false;
    }
};

const globalLogout = () => {
    localStorage.clear();
    const redirectParam = getRedirectParam();
    if (redirectParam) {
        window.location.replace(`/?${redirectParam}`);
    } else {
        window.location.replace("/");
    }
    logout();
};

const updateToken = async (refreshToken = "") => {
    const email =
        JSON.parse(localStorage.getItem("ploomber_user_session"))?.email || "";
    const url = getURL(`users/auth/renew`);
    const headers = new Headers({
        "Content-Type": "application/json",
    });
    const body = JSON.stringify({
        refresh_token: refreshToken,
        username: email,
    });
    let res;
    try {
        const response = await HTTPRequest.post(url, {
            headers,
            body,
        });
        res = response.json();
    } catch (err) {
        globalLogout();
    }
    return res;
};

const checkAndUpdateToken = async () => {
    await asyncLock.acquire();

    // Ensure API Key doesn't present in the localstorage anymore
    const apiKey = localStorage.getItem("ploomber_api_key");
    const token = JSON.parse(getAccessToken());
    if (apiKey || !token) {
        globalLogout();
    }
    // Make sure API Key is removed
    localStorage.removeItem("ploomber_api_key");

    const getCurrentTimeStamp = () => Math.floor(Date.now() / 1000);
    // When the access token is expired
    if (
        token?.expires_at <
        getCurrentTimeStamp() + REFRESH_TOKEN_WHEN_EXPIRED_IN_MINUTES * 60
    ) {
        const newToken = await updateToken(token?.refresh_token);
        setAccessToken(JSON.stringify(newToken));
    }
    asyncLock.release();
};

const getDefaultRequestHeaders = async () => {
    // Auto check refresh
    await checkAndUpdateToken();

    const token = JSON.parse(getAccessToken());
    const headers = new Headers({
        Authorization: `Bearer ${token?.access_token}`,
    });
    if (!token) {
        throw Error("No Access Token");
    }
    return headers;
};

const listAPIKeys = async (): Promise<APIKey[]> => {
    const url = getURL(`keys`);
    const headers = await getDefaultRequestHeaders();
    const response = await HTTPRequest.get(url, headers);
    const apiKeys = response.json() as APIKey[];
    return Promise.resolve(apiKeys);
};

const createAPIKey = async (): Promise<APIKey> => {
    const url = getURL(`keys`);
    const headers = await getDefaultRequestHeaders();
    const response = await HTTPRequest.post(url, { headers });
    const apiKeys = response.json() as APIKey;
    return Promise.resolve(apiKeys);
};

const deleteAPIKey = async (apiKey: string): Promise<void> => {
    const url = getURL(`keys/${apiKey}`);
    const headers = await getDefaultRequestHeaders();
    const response = await HTTPRequest.del(url, { headers });
    if (response.status !== 200) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Unknown error occurred.");
    }
};

const getUserInfo = async (): Promise<UserSession> => {
    const url = getURL("users/me");
    const headers = await getDefaultRequestHeaders();
    const response = await HTTPRequest.get(url, headers);
    return Promise.resolve(response.json());
};

const getResourcesInfo = async (): Promise<ResourcesInfo> => {
    const url = getURL("users/me/resources");
    const headers = await getDefaultRequestHeaders();
    const response = await HTTPRequest.get(url, headers);
    return Promise.resolve(response.json());
};

const login = async (email: string, password: string): Promise<UserSession> => {
    const url = getURL("users/auth");
    const headers = new Headers({
        "Content-Type": "application/x-www-form-urlencoded",
    });

    const formData = new URLSearchParams({
        grant_type: "password",
        username: email,
        password,
    });
    const response = await HTTPRequest.post(url, {
        headers,
        body: formData,
    });

    const userLoginResponse = (await response.json()) as UserLoginResponse;
    return userLoginResponse;
};

const loginSocial = async (code, redirectURI): Promise<UserSession> => {
    const url = getURL("users/auth/social");
    const headers = new Headers({
        "Content-Type": "application/json",
    });

    const body = JSON.stringify({
        code,
        redirect_uri: redirectURI,
    });
    const response = await HTTPRequest.post(url, {
        headers,
        body,
    });

    const userLoginResponse = (await response.json()) as UserLoginResponse;
    return userLoginResponse;
};

const signup = async (email: string, password: string): Promise<boolean> => {
    const url = getURL("users");
    const headers = new Headers({
        "Content-Type": "application/x-www-form-urlencoded",
    });
    const formData = new URLSearchParams();
    formData.append("grant_type", "password");
    formData.append("username", email);
    formData.append("password", password);
    const response = await HTTPRequest.post(url, {
        headers,
        body: formData,
    });

    return Promise.resolve(true);
};

const restartProject = async (projectName: string): Promise<boolean> => {
    const url = getURL(`projects/${projectName}/service_start`);
    const headers = new Headers({
        "Content-Type": "application/json",
    });

    const response = await HTTPRequest.patch(url, {
        headers,
    });

    return Promise.resolve(response.json());
};

const updateProjectLabels = async (
    projectId: string,
    labels: string[]
): Promise<boolean> => {
    const url = getURL(`projects/${projectId}/labels`);
    const headers = await getDefaultRequestHeaders();
    headers.append("Content-Type", "application/json");

    const body = JSON.stringify(labels);

    const response = await HTTPRequest.put(url, {
        headers,
        body,
    });

    return Promise.resolve(response.json());
};

const checkProjectStatus = async (projectName: string): Promise<boolean> => {
    const url = getURL(`projects/${projectName}/is_up`);
    const headers = new Headers({
        "Content-Type": "application/json",
    });

    const response = await HTTPRequest.get(url, {
        headers,
    });

    return Promise.resolve(response.json());
};

const forgotPassword = async (email: string): Promise<boolean> => {
    const url = getURL("users/password/forgot");
    const headers = new Headers({
        "Content-Type": "application/json",
    });

    const body = JSON.stringify({ email });
    const response = await HTTPRequest.post(url, {
        headers,
        body,
    });

    return Promise.resolve(response.json());
};

const resendConfirmation = async (email: string): Promise<boolean> => {
    const url = getURL("confirmations");
    const headers = new Headers({
        "Content-Type": "application/json",
    });

    const body = JSON.stringify({ email });
    const response = await HTTPRequest.post(url, {
        headers,
        body,
    });

    return Promise.resolve(response.json());
};

const getUserProjects = async (
    pageSize: number,
    page: number
): Promise<Project> => {
    let url;
    if (pageSize && page) {
        url = getURL(`projects?page_size=${pageSize}&page=${page}`);
    } else {
        url = getURL(`projects`);
    }

    const headers = await getDefaultRequestHeaders();
    const response = await HTTPRequest.get(url, headers);
    const projects = response.json() as Project;
    return Promise.resolve(projects);
};

const getUserProject = async (projectId: string): Promise<Project> => {
    const url = getURL(`projects/${projectId}`);
    const headers = await getDefaultRequestHeaders();
    const response = await HTTPRequest.get(url, headers);
    const project = (await response.json()) as ProjectJobResponse;
    return Promise.resolve(project);
};

const getJob = async (
    jobId: string,
    apiVersion: string = ""
): Promise<JobResponse> => {
    const url = getURL(`jobs/${jobId}`, apiVersion);
    const headers = await getDefaultRequestHeaders();
    const response = await HTTPRequest.get(url, headers);
    const job = (await response.json()) as JobResponse;
    return Promise.resolve(job);
};

const getJobInfo = async (jobId: string): Promise<JobInfoResponse> => {
    const url = getURL(`jobs/${jobId}/info`);
    const headers = await getDefaultRequestHeaders();
    const response = await HTTPRequest.get(url, headers);
    const jobInfo = (await response.json()) as JobInfoResponse;
    return Promise.resolve(jobInfo);
};

const createJob = async (
    formData: FormData,
    projectType: string,
    projectId: string,
    onStatusUpdate: HTTPRequest.OnStatusUpdate
): Promise<Job> => {
    let url = getURL(`jobs/webservice/${projectType}`);
    if (projectId !== "") {
        url += `?project_id=${projectId}`;
    }
    const headers = await getDefaultRequestHeaders();
    const response = await HTTPRequest.postWithStatus(
        url,
        { headers, body: formData },
        onStatusUpdate
    );
    const job = JSON.parse(response) as Job;
    return Promise.resolve(job);
};

const startJob = async (jobId: string): Promise<JobResponse> => {
    const url = getURL(`jobs/${jobId}/service_start`);
    const headers = await getDefaultRequestHeaders();
    const response = await HTTPRequest.patch(url, { headers });
    const job = (await response.json()) as JobResponse;
    return Promise.resolve(job);
};

const stopJob = async (jobId: string): Promise<JobResponse> => {
    const url = getURL(`jobs/${jobId}/service_stop`);
    const headers = await getDefaultRequestHeaders();
    const response = await HTTPRequest.patch(url, { headers });
    const job = (await response.json()) as JobResponse;
    return Promise.resolve(job);
};

const manageEKSJob = async (
    jobId: string,
    action: "start" | "stop"
): Promise<JobResponse> => {
    const url = getURL(`v2/jobs/${jobId}/service_${action}`);
    const headers = await getDefaultRequestHeaders();
    const response = await HTTPRequest.patch(url, { headers });
    return response.json() as Promise<JobResponse>;
};

const stopEKSJob = (jobId: string): Promise<JobResponse> =>
    manageEKSJob(jobId, "stop");

const startEKSJob = (jobId: string): Promise<JobResponse> =>
    manageEKSJob(jobId, "start");

const getEKSJobLogs = async (
    jobId: string,
    startTime = null
): Promise<Logs> => {
    const url = getURL(
        `v2/jobs/${jobId}/logs${startTime ? `?start_time=${startTime}` : ""}`
    );
    const headers = await getDefaultRequestHeaders();
    const response = await HTTPRequest.get(url, headers);
    const { logs } = (await response.json()) as JobLogsResponse;
    return logs;
};

const getJobLogs = async (jobId: string, startTime = null): Promise<Logs> => {
    let url;
    if (startTime !== null) {
        url = getURL(`jobs/${jobId}/logs?start_time=${startTime}`);
    } else {
        url = getURL(`jobs/${jobId}/logs`);
    }
    const headers = await getDefaultRequestHeaders();
    const response = await HTTPRequest.get(url, headers);
    const jobLogsResponse = (await response.json()) as JobLogsResponse;
    const { logs } = jobLogsResponse;
    return Promise.resolve(logs);
};

const getProjectLogs = async (
    projectId: string,
    startTime = null
): Promise<Logs> => {
    let url;
    if (startTime !== null) {
        url = getURL(`projects/${projectId}/logs?start_time=${startTime}`);
    } else {
        url = getURL(`projects/${projectId}/logs`);
    }
    const headers = await getDefaultRequestHeaders();
    const response = await HTTPRequest.get(url, headers);
    const jobLogsResponse = (await response.json()) as ProjectJobResponse;
    const { logs } = jobLogsResponse;
    return Promise.resolve(logs);
};

const getApplicationLogs = async (projectId: string): Promise<string> => {
    const url = getURL(`projects/${projectId}/logs/application`);
    const headers = await getDefaultRequestHeaders();
    const response = await HTTPRequest.get(url, headers);
    const logs = await response.json();
    return Promise.resolve(logs);
};

const deleteProject = async (
    projectId: string,
    apiVersion: string = ""
): Promise<Project> => {
    const url = getURL(`projects/${projectId}`, apiVersion);
    const headers = await getDefaultRequestHeaders();
    const response = await HTTPRequest.del(url, { headers });
    const deleteProjectResponse = (await response.json()) as Project;
    return Promise.resolve(deleteProjectResponse);
};

const getGithubTemplate = async (
    owner: string,
    repo: string,
    ref: string
): Promise<Blob> => {
    const url = getURL(`projects/template/github/${owner}/${repo}/${ref}`);
    const headers = await getDefaultRequestHeaders();
    headers.append("Cache-Control", "no-cache");
    const response = await HTTPRequest.get(url, headers);
    const blob = await response.blob();
    return Promise.resolve(blob);
};

const getPreviousFiles = async (projectId: string) => {
    const url = getURL(
        `jobs/${projectId}/files?exclude_preset_dockerfile=true`
    );
    const headers = await getDefaultRequestHeaders();
    const response = await HTTPRequest.get(url, headers);
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = "app.zip";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);
};

const createPaymentLink = async (
    upgradeType: string
): Promise<{ payment_url: string }> => {
    const url = getURL(`stripe/${upgradeType}`);
    const headers = await getDefaultRequestHeaders();
    const response = await HTTPRequest.post(url, { headers });
    const paymentUrl = response.json();

    return Promise.resolve(paymentUrl);
};

const createAddCreditsLink = async (
    amount: number,
    credits: number
): Promise<{ payment_url: string }> => {
    const url = getURL(`stripe/credits/${amount}?usage=${credits}`);
    const headers = await getDefaultRequestHeaders();
    const response = await HTTPRequest.post(url, { headers });
    const paymentUrl = response.json();

    return Promise.resolve(paymentUrl);
};

const getSubscription = async (): Promise<Subscription> => {
    const url = getURL("stripe/subscription");
    const headers = await getDefaultRequestHeaders();
    const response = await HTTPRequest.get(url, headers);
    const content = response.json();
    return Promise.resolve(content);
};

const cancelSubscription = async (reason: string): Promise<Subscription> => {
    const url = getURL(`stripe?reason=${encodeURIComponent(reason)}`);
    const headers = await getDefaultRequestHeaders();
    const response = await HTTPRequest.del(url, { headers });
    const content = response.json();
    return Promise.resolve(content);
};

const getTrialInfo = async (): Promise<UserSession> => {
    const url = getURL(`stripe/trial`);
    const headers = await getDefaultRequestHeaders();
    const response = await HTTPRequest.get(url, headers);
    const trialInfo = response.json();

    return Promise.resolve(trialInfo);
};

const checkProjectNameAvailability = async (name: string): Promise<boolean> => {
    const url = getURL(`projects/${name}/availability`);
    const headers = await getDefaultRequestHeaders();
    const response = await HTTPRequest.get(url, headers);
    const isAvailable = await response.json();
    return Promise.resolve(isAvailable);
};

const deleteCustomDomain = async (domain: string) => {
    const url = getURL(`domains/${domain}`);
    const headers = await getDefaultRequestHeaders();
    const response = await HTTPRequest.del(url, { headers });
    return response;
};

const registerNewCustomDomain = async (domain: string, projectId: string) => {
    const url = getURL(`domains/${domain}`);
    const headers = await getDefaultRequestHeaders();
    headers.append("Content-Type", "application/json");
    const body = JSON.stringify({ project_id: projectId });

    const response = await HTTPRequest.post(url, {
        headers,
        body,
    });

    return Promise.resolve(response);
};

const getCustomDomains = async (projectId: string) => {
    const endpoint = projectId ? `domains/projects/${projectId}` : "domains";
    const url = getURL(endpoint);
    const headers = await getDefaultRequestHeaders();
    const response = await HTTPRequest.get(url, headers);
    const domains = (await response.json()) as CustomDomain[];
    return Promise.resolve(domains);
};

const updateOnboardingFinished = async (): Promise<boolean> => {
    const url = getURL(`users/onboarding`);
    const headers = await getDefaultRequestHeaders();
    const response = await HTTPRequest.post(url, { headers });
    const updated = await response.json();

    localStorage.setItem("ploomber_onboarded", "true");

    return Promise.resolve(updated);
};

const analyzeZipfile = async (
    formData: FormData,
    timeout: number
): Promise<Job> => {
    const url = getURL(`analysis`);
    const headers = await getDefaultRequestHeaders();

    const timeoutPromise = new Promise<Job>((_, reject) => {
        const id = setTimeout(() => {
            clearTimeout(id);
            reject(new Error("Request timed out"));
        }, timeout);
    });

    const request = HTTPRequest.post(url, { headers, body: formData }).then(
        (response) => response.json()
    );

    return Promise.race([timeoutPromise, request]);
};

interface UpdateDomainOptions {
    isActive?: boolean;
    isDefault?: boolean;
}

const updateDomain = async (
    domain: string,
    projectId: string,
    options: UpdateDomainOptions = {},
    apiVersion: string = ""
) => {
    const url = getURL(`domains/${domain}`, apiVersion);
    const headers = await getDefaultRequestHeaders();
    headers.append("Content-Type", "application/json");

    const body: {
        project_id: string;
        is_active?: boolean;
        is_default?: boolean;
    } = { project_id: projectId };

    const { isActive, isDefault } = options;

    if (isActive !== undefined) {
        body.is_active = isActive;
    }

    if (isDefault !== undefined) {
        body.is_default = isDefault;
    }

    const response = await HTTPRequest.put(url, {
        headers,
        body: JSON.stringify(body),
    });

    return Promise.resolve(response);
};

export const getExampleApplications = async (): Promise<ExamplesResponse> => {
    const url = getURL(`projects/examples`);
    const headers = await getDefaultRequestHeaders();
    const response = await HTTPRequest.get(url, headers);
    return Promise.resolve(response.json());
};

const getUserCredits = async (): Promise<UserCredits> => {
    const url = getURL(`billings/credits`);
    const headers = await getDefaultRequestHeaders();
    const response = await HTTPRequest.get(url, headers);
    return Promise.resolve(response.json());
};

const getUserBillingHistory = async (): Promise<BillingHistoryResponse> => {
    const url = getURL(`billings`);
    const headers = await getDefaultRequestHeaders();
    const response = await HTTPRequest.get(url, headers);
    return Promise.resolve(response.json());
};

const verifyUpgrade = async (
    sessionId: string
): Promise<VerifyUpgradeResponse> => {
    const url = getURL(`stripe/verify/${sessionId}`);
    const headers = await getDefaultRequestHeaders();
    const response = await HTTPRequest.get(url, headers);
    return Promise.resolve(response.json());
};

export default {
    AsyncLock,
    updateToken,
    listAPIKeys,
    createAPIKey,
    deleteAPIKey,
    getUserInfo,
    getResourcesInfo,
    login,
    loginSocial,
    logout,
    getUserProjects,
    getUserProject,
    getJob,
    getJobInfo,
    createJob,
    startJob,
    stopJob,
    signup,
    getEKSJobLogs,
    getJobLogs,
    getProjectLogs,
    getApplicationLogs,
    getPreviousFiles,
    deleteProject,
    forgotPassword,
    resendConfirmation,
    createPaymentLink,
    getSubscription,
    cancelSubscription,
    restartProject,
    checkProjectStatus,
    updateProjectLabels,
    checkProjectNameAvailability,
    getGithubTemplate,
    getCustomDomains,
    registerNewCustomDomain,
    deleteCustomDomain,
    updateOnboardingFinished,
    analyzeZipfile,
    getTrialInfo,
    updateDomain,
    getExampleApplications,
    getDefaultRequestHeaders,
    getUserCredits,
    getUserBillingHistory,
    stopEKSJob,
    startEKSJob,
    createAddCreditsLink,
    verifyUpgrade,
};
