import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import AddIcon from "@mui/icons-material/Add";

import { AppContext } from "../context/AppContext";
import { ActionButton } from "./UI";
import { UserType } from "../models/enum.ts";
import { AccountContext } from "../features/user/Account";
import AddCreditsDialog from "../features/settings/components/AddCreditsDialog";

function CreditsButton() {
    const [openAddCreditsDialog, setOpenAddCreditsDialog] = useState(false);
    const { creditInfo } = useContext(AppContext);
    const { userType } = useContext(AccountContext);

    return userType !== UserType.COMMUNITY.value ? (
        <>
            <ActionButton
                data-testid="addCreditsButton"
                style={{ marginRight: "1rem" }}
                startIcon={<AddIcon />}
                onClick={() => setOpenAddCreditsDialog(true)}
            >
                CREDITS: $
                {(
                    creditInfo.total_available_credits - creditInfo.used_credits
                ).toFixed(2)}
            </ActionButton>

            <AddCreditsDialog
                open={openAddCreditsDialog}
                onClose={() => {
                    setOpenAddCreditsDialog(false);
                }}
            />
        </>
    ) : null;
}

export default CreditsButton;
