import React, { useContext, useState, useEffect, useRef } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import {
    Box,
    CircularProgress,
    LinearProgress,
    Typography,
} from "@mui/material";
import CloudDownload from "@mui/icons-material/CloudDownloadOutlined";
import UserInstruction from "../../components/UserInstruction";
import { Block, BlockContent, BlockHeader } from "../../components/Block";
import { BlockTable, SelectOneTable } from "../../components/BlockTable";
import { ActionButton } from "../../components/UI";
import { AppContext } from "../../context/AppContext";
import { ProjectContext } from "../../context/ProjectContext";
import { AccountContext } from "../user/Account";
import ploomberAPI from "../../services/ploomberAPI.ts";
import BlockToggleButton from "../../components/Block/BlockToggleButton";
import telemetry from "../../services/telemetry.ts";
import {
    getFrameworkFromZip as getFrameworkFromGithubZip,
    getTemplate as getGithubTemplate,
} from "../../services/githubAPI";
import {
    parseErrorMessage,
    getFirstExampleByFramework,
} from "../../utils/utils.ts";
import AuthenticationController from "./controllers/AuthenticationController";
import SelectProjectController from "./controllers/SelectProjectController";
import SelectProjectFrameworkGridController from "./controllers/SelectProjectFrameworkGridController";
import SetSecretsController from "./controllers/SetSecretsController";
import SelectCPUController from "./controllers/SelectCPUController";
import SelectRAMController from "./controllers/SelectRAMController";
import SelectGPUController from "./controllers/SelectGPUController";
import SetProjectLabelsController from "./controllers/SetProjectLabelsController";
import SetProjectNameController from "./controllers/SetProjectNameController";
import UploadProgress from "../../components/UploadProgress";
import {
    clearReferralTemplate,
    getReferralTemplate,
} from "../../utils/CustomOnboarding";
import Loader from "../../components/Loader";
import { useProductTourContext } from "../../utils/ProductTourContext";
import UploadNewApplicationFiles from "../../components/UploadBox";
import SetStorageController from "./controllers/SetStorageController";
import DeploymentCostRenderer from "./components/DeploymentCostRenderer";

const NEW_PROJECT_ITEM = { id: "Create new application" };

const Files = {
    Notebook: "Notebook",
    Requirements: "Requirements",
    Example: "Example",
    Zipped: "Zipped",
};

function CreateApplication() {
    const location = useLocation();
    const { setWaitToStartTheTour, tourInProgress } = useProductTourContext();
    const [searchParams] = useSearchParams();
    const [referredProjectId, setReferredProjectId] = useState(
        searchParams.get("referredProjectId")
    );
    const [referredProjectType, setReferredProjectType] = useState();
    const [isLoadingProject, setIsLoadingProject] = useState(true);

    const [selectedFramework, setSelectedFramework] = useState("voila");
    const defaultFramework = "voila";
    const [isRedeploying] = useState(Boolean(referredProjectId));

    const { navigate, updateSnackbarStatus } = useContext(AppContext);
    const {
        userType,
        getUserConfiguration,
        resourcesInfo,
        setResourcesInfo,
        canUserAccessComponent,
    } = useContext(AccountContext);

    const [notebookFile, setNotebookFile] = useState();
    const [requirementsFile, setRequirementsFile] = useState();
    const [defaultFiles, setDefaultFiles] = useState([]);
    const [zippedFile, setZippedFile] = useState();

    const {
        resourcesInfo: currentProjectResourceInfo,
        setResourcesInfo: setCurrentProjectResourceInfo,
    } = useContext(ProjectContext);

    const [projects, setProjects] = useState([NEW_PROJECT_ITEM]);
    const [project, setProject] = useState(NEW_PROJECT_ITEM);

    const [applicationCreated, setApplicationCreated] = useState(false);

    const [useExampleFiles, setUseExampleFiles] = useState(false);
    const [usePreviousFiles, setUsePreviousFiles] = useState(false);
    const [authentication, setAuthentication] = useState();
    const [authEnabled, setAuthEnabled] = useState(false);
    const [storageEnabled, setStorageEnabled] = useState(false);
    const [
        forceDisableStorageBecauseForbidden,
        setForceDisableStorageBecauseForbidden,
    ] = useState(false);

    const [selectedCpu, setSelectedCpu] = useState();
    const [selectedRam, setSelectedRam] = useState();
    const [selectedGpu, setSelectedGpu] = useState();
    const [selectedStorage, setSelectedStorage] = useState(0);
    const [selectedRamList, setSelectedRamList] = useState();

    const [cpuToRamMapping, setCpuToRamMapping] = useState({});
    const [gpu, setGpu] = useState({});

    const [selectedExample, setSelectedExample] = useState();

    const [labels, setLabels] = useState([]);
    const [projectName, setProjectName] = useState();
    const [customProjectName, setCustomProjectName] = useState();
    const [projectNameValid, setProjectNameValid] = useState(true);

    const fileError = useRef();

    const [secrets, setSecrets] = useState([{ key: "", value: "" }]);

    const cpuToRamMappingVal = getUserConfiguration("cpuToRamMapping");
    const gpuVal = getUserConfiguration("gpu");
    const storageVal = getUserConfiguration("storage");
    const [carryOverStorage, setCarryOverStorage] = useState(false);

    const [authCredentialsValid, setAuthCredentialsValid] = useState(true);
    const [carryOverAuth, setCarryOverAuth] = useState(false);

    const [uploadProgress, setUploadProgress] = useState(null);

    const [allExamples, setAllExamples] = useState();

    function updateCurrentProjectResourceInfo(projectInfo) {
        setCurrentProjectResourceInfo({
            currentCpu: projectInfo.jobs[0].cpu,
            currentRam: projectInfo.jobs[0].ram,
            currentGpu: projectInfo.jobs[0].gpu,
            currentStorage: projectInfo.jobs[0].storage,
        });
    }

    const getCurrentProjectId = () => {
        let projectId = project?.id;
        if (projectId === NEW_PROJECT_ITEM.id) {
            projectId = "";
        }
        return projectId;
    };

    const handleSelectExample = (example) => {
        setSelectedExample(example);
    };

    const handleSelectFramework = (framework) => {
        setSelectedFramework(framework);
        handleSelectExample(getFirstExampleByFramework(framework, allExamples));
    };

    const updateCurrentExamples = async (framework = selectedFramework) => {
        try {
            const examplesFromAPI = await ploomberAPI.getExampleApplications();
            setAllExamples(examplesFromAPI);
            handleSelectFramework(framework);
        } catch (err) {
            console.error("Error updating examples:", err);
        }
    };

    const logTelemetryFileUpdate = (fileType) => {
        const projectId = getCurrentProjectId();

        telemetry.log(
            `${telemetry.Pages.CreateApplication}-UploadFile-${fileType}`,
            {
                metadata: {
                    projectId,
                },
            }
        );
    };

    function updateApplicationState({
        ramListValue = [],
        cpuValue = 0,
        ramValue = 0,
        secretKeys = [],
        gpuValue = 0,
        storageValue = 0,
        framework = defaultFramework,
        projectType = null,
        projectLabels = [],
        customName = undefined,
        authState = false,
        storageState = false,
    }) {
        setSelectedRamList(ramListValue);
        setSelectedCpu(cpuValue);
        setSelectedRam(ramValue);
        setSelectedGpu(gpuValue);
        setSelectedStorage(storageValue);
        setStorageEnabled(storageState);
        setSecrets(secretKeys);
        updateCurrentExamples(framework);
        setReferredProjectType(projectType);
        setLabels(projectLabels);
        setCustomProjectName(customName);
        setAuthEnabled(authState);
    }

    const resetToDefaultSettings = () => {
        setCpuToRamMapping(cpuToRamMappingVal);
        setGpu(gpuVal);
        const sortedKeys = Object.keys(cpuToRamMappingVal).sort(
            (a, b) =>
                Number(cpuToRamMappingVal[a].value) -
                Number(cpuToRamMappingVal[b].value)
        );
        const firstCpuKey = sortedKeys[0];
        const firstCpuObj = cpuToRamMappingVal[firstCpuKey];
        const firstGpuObj = gpuVal[0];

        updateApplicationState({
            ramListValue: firstCpuObj.ramOptions,
            cpuValue: firstCpuObj.value,
            ramValue: firstCpuObj.ramOptions[0][0],
            secretKeys: [],
            gpuValue: firstGpuObj.value,
            storageValue: storageVal,
            framework: defaultFramework,
            projectType: null,
            projectLabels: [],
            customName: undefined,
            authState: false,
            storageState: false,
        });
    };

    const setLatestJobDetails = async (projectInfo) => {
        if (projectInfo?.id === NEW_PROJECT_ITEM.id) {
            // Simply reset to the default framework and hardware settings
            resetToDefaultSettings();
        } else {
            const latestJob = projectInfo.jobs[projectInfo.jobs.length - 1];
            const cpuValue =
                latestJob.cpu[2] === "0" ? latestJob.cpu[0] : latestJob.cpu;
            const ramOptions = cpuToRamMappingVal[cpuValue]
                ? cpuToRamMappingVal[cpuValue].ramOptions
                : [];
            const ramValue = parseInt(latestJob.ram, 10).toString();
            const gpuValue = parseInt(latestJob.gpu, 10).toString();
            const storageValue = parseInt(
                latestJob.storage || 0,
                10
            ).toString();
            const storageState =
                storageValue && storageValue !== "0" && storageValue !== ""; // Check if exists and not "0" or ""

            const authValue = latestJob.auth_enabled;
            const updatedSecrets = projectInfo.secrets
                ? projectInfo.secrets.map((secret) => ({
                      key: secret,
                      value: "", // Placeholder value
                      previous: true, // Assuming previous secrets should be disabled
                  }))
                : [];

            updateApplicationState({
                ramListValue: ramOptions,
                cpuValue,
                ramValue,
                secretKeys: updatedSecrets,
                gpuValue,
                storageValue,
                framework: projectInfo.type,
                projectType: projectInfo.type,
                projectLabels: projectInfo.labels,
                customName: projectInfo.name,
                authState: authValue,
                storageState,
            });
        }
    };

    // ensure that the storage is locked when the user isn't allowed to adjust it
    useEffect(() => {
        setForceDisableStorageBecauseForbidden(
            !canUserAccessComponent("selectStorage")
        );
    }, [userType]);

    // get resources info for current project
    useEffect(() => {
        ploomberAPI
            .getResourcesInfo()
            .then((resInfo) => {
                setResourcesInfo(resInfo);
            })
            .catch((err) => {
                console.error("Error fetching resources info:", err);
            });
    }, []);

    // RAM selection behaves lazily, force a rerender by adding referredProjectId and resourcesInfo to the dependencies (for pricing updates)
    useEffect(() => {
        if (selectedCpu) {
            setSelectedRamList([...cpuToRamMapping[selectedCpu].ramOptions]);
            setSelectedRam(cpuToRamMapping[selectedCpu].ramOptions[0][0]);
        }
    }, [currentProjectResourceInfo, resourcesInfo, selectedCpu]);

    useEffect(() => {
        resetToDefaultSettings();
        ploomberAPI
            .getUserProjects()
            .then(async (projectsResp) => {
                // Create new project will be placed as 1st option as default
                const projectsArray = [
                    NEW_PROJECT_ITEM,
                    ...projectsResp.projects,
                ];
                // Prepare dropdown project selection
                setProjects(projectsArray);

                // Setup default project mapping
                setCpuToRamMapping(cpuToRamMappingVal);
                setGpu(gpuVal);
                // If the referredProjectId is passed in the URL, check if it exists and set
                // Otherwise, make the 1st option as default
                if (
                    referredProjectId &&
                    referredProjectId !== NEW_PROJECT_ITEM.id
                ) {
                    const matchedProject = projectsArray.find(
                        (obj) => obj.id === referredProjectId
                    );
                    if (matchedProject) {
                        setProject(matchedProject);
                    } else {
                        setProject(projectsArray[0]);
                    }
                    setIsLoadingProject(true);
                    const projectInfo = await ploomberAPI.getUserProject(
                        referredProjectId
                    );
                    updateCurrentProjectResourceInfo(projectInfo);
                    const hasDeployments =
                        projectInfo && projectInfo.jobs.length > 0;
                    setUsePreviousFiles(hasDeployments);
                    setLatestJobDetails(projectInfo);
                }
            })
            .catch((err) => {
                console.error("Error fetching project details:", err);
                navigate("/applications/create", { replace: true });
            })
            .finally(() => {
                setIsLoadingProject(false);
            });

        telemetry.log(telemetry.Events.PageView);
    }, []);

    useEffect(() => {
        if (referredProjectId) {
            ploomberAPI
                .getUserProject(referredProjectId)
                .then((projectInfo) => {
                    updateCurrentProjectResourceInfo(projectInfo);
                })
                .catch((err) => {
                    console.error("Error fetching project details:", err);
                });
        } else {
            setCurrentProjectResourceInfo(undefined);
        }
    }, [referredProjectId]);

    /**
     * Triggered when the user selects a file in the notebook file input
     * */
    const handleNotebookFileInputChange = (file) => {
        if (!usePreviousFiles) {
            logTelemetryFileUpdate(Files.Notebook);

            setTimeout(() => {
                setNotebookFile(file);
            }, 100);
        }
    };

    /**
     * Triggered when the user selects a file in the requirements file input
     */
    const handleRequirementsFileInputChange = (file) => {
        if (!usePreviousFiles) {
            logTelemetryFileUpdate(Files.Requirements);

            setTimeout(() => {
                setRequirementsFile(file);
            }, 100);
        }
    };

    /**
     * Triggered when the user selects a file in the zip file input
     */
    const handleZippedFileInputChange = (file) => {
        if (!usePreviousFiles) {
            logTelemetryFileUpdate(Files.Zipped);

            setTimeout(() => {
                setZippedFile(file);
            }, 100);
        }
    };

    const handleRetrievePreviousFiles = (projectId) => {
        ploomberAPI.getPreviousFiles(projectId);
    };

    const checkFormErrors = (showMessage = true) => {
        let errMessage = "";

        // If authentication selected, validate username and password
        if (authentication) {
            const isValidStructure =
                "password" in authentication && "username" in authentication;

            if (!isValidStructure) {
                return true;
            }

            if (
                authentication.password.length === 0 ||
                authentication.username.length === 0
            ) {
                return true;
            }
        }

        if (useExampleFiles || usePreviousFiles) {
            return false;
        }

        // voila framework requires either a zip file or both a notebook and a requirements file
        // when there is no zip file, check for both notebook / requirements.txt first
        if (selectedFramework === "voila" && !zippedFile) {
            if (!notebookFile) {
                if (showMessage) {
                    errMessage = `Please upload notebook file`;
                    fileError.current.style.display = "inline";

                    updateSnackbarStatus({
                        message: errMessage,
                        severity: "error",
                    });
                }

                if (fileError.current) {
                    fileError.current.style.display = "none";
                }

                return true;
            }

            if (!requirementsFile) {
                if (showMessage) {
                    errMessage = `Please upload requirements file`;
                    fileError.current.style.display = "inline";
                    updateSnackbarStatus({
                        message: errMessage,
                        severity: "error",
                    });
                }

                if (fileError.current) {
                    fileError.current.style.display = "none";
                }

                return true;
            }
            return false;
        }
        // docker or python apps require a zip file
        const zippedBasedFramework = [
            "voila",
            "docker",
            "panel",
            "streamlit",
            "solara",
            "shiny-r",
            "dash",
            "flask",
            "chainlit",
        ].includes(selectedFramework);

        if (zippedBasedFramework && !zippedFile) {
            if (showMessage) {
                errMessage = `Please upload a zip file`;
                updateSnackbarStatus({
                    message: errMessage,
                    severity: "error",
                });
            }

            return true;
        }

        return false;
    };

    const createApplicationClick = async (e) => {
        e.preventDefault();

        setApplicationCreated(true);
        const hasErrors = checkFormErrors();
        if (hasErrors) {
            return;
        }

        const formData = new FormData();

        if (authentication) {
            formData.append("authentication", JSON.stringify(authentication));
        }

        formData.append("carry_over_auth", carryOverAuth);

        formData.append("labels", JSON.stringify(labels));
        formData.append("cpu", selectedCpu);
        formData.append("ram", selectedRam);
        formData.append("gpu", selectedGpu);
        formData.append("storage", selectedStorage);

        if (projectName) {
            formData.append("project_name", projectName);
        }

        let files;

        if (usePreviousFiles) {
            formData.append("carry_over_files", usePreviousFiles);
            files = [];
        } else if (useExampleFiles) {
            formData.append("example_id", selectedExample.id);
            files = [];
        } else if (selectedFramework === "voila" && !zippedFile) {
            files = [notebookFile, requirementsFile];
        } else {
            files = [zippedFile];
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const file of files) {
            formData.append("files", file);
        }

        const nonEmptySecrets = secrets
            .filter((secret) => secret.key.trim() !== "")
            .map((p) => ({
                key: p.key,
                value: p.value,
            }));

        if (nonEmptySecrets.length > 0) {
            formData.append("secrets", JSON.stringify(nonEmptySecrets));
        }

        const projectId = getCurrentProjectId();
        telemetry.log(`${telemetry.Pages.CreateApplication}-Create-Start`, {
            metadata: {
                projectId,
            },
        });

        try {
            const newJob = await ploomberAPI.createJob(
                formData,
                selectedFramework,
                projectId,
                setUploadProgress
            );
            telemetry.log(
                `${telemetry.Pages.CreateApplication}-Create-Success`,
                {
                    metadata: {
                        projectId,
                        job: JSON.stringify(newJob),
                    },
                }
            );
            navigate(
                `/applications/${projectName || newJob.project_id}/${newJob.id}`
            );
        } catch (err) {
            telemetry.log(`${telemetry.Pages.CreateApplication}-Create-Error`, {
                metadata: {
                    projectId,
                    error: JSON.stringify(err),
                },
            });
            updateSnackbarStatus({
                message: parseErrorMessage(err),
                severity: "error",
            });
            setApplicationCreated(false);
        }
    };

    const [templateWasUse, setTemplateWasUse] = useState(false);
    const cancelSetTemplateRef = useRef(false);
    const [isDownloadingTemplate, setIsDownloadingTemplate] = useState(false);
    const setCancelSetTemplate = (value) => {
        cancelSetTemplateRef.current = value;
        if (value) setIsDownloadingTemplate(false);
    };

    const useReferalExample = async () => {
        const repoUrl = getReferralTemplate();
        if (!repoUrl) {
            return;
        }
        try {
            setIsDownloadingTemplate(true);
            setWaitToStartTheTour(true);
            cancelSetTemplateRef.current = false;

            // Retreive zip file of the specify github repo directory
            const { file, zip } = await getGithubTemplate(repoUrl);
            const framework = await getFrameworkFromGithubZip(zip);

            // Update UI
            if (!cancelSetTemplateRef.current) {
                setSelectedFramework(framework);
                setUseExampleFiles(false);
                setUsePreviousFiles(false);
                setDefaultFiles([file]);
                setZippedFile(file);

                const templateName =
                    file.path.charAt(0).toUpperCase() +
                    file.path.replace(".zip", "").slice(1);
                updateSnackbarStatus({
                    message: `${templateName} loaded! Click on the CREATE button at the bottom to deploy`,
                    severity: "info",
                });
            }
        } catch (err) {
            err.title = "Template";
            updateSnackbarStatus({
                message: err.message || parseErrorMessage(err),
                severity: "warning",
            });
        } finally {
            setTemplateWasUse(true);
            setIsDownloadingTemplate(false);
            setWaitToStartTheTour(false);
        }
    };

    // eslint-disable-next-line
    useEffect(() => {
        // Clean up template only when unmounting the component - So Product tour can use it
        return () => {
            if (templateWasUse) clearReferralTemplate();
        };
    }, [templateWasUse]);

    // Clean up template on unmount - So Product tour can use it
    // eslint-disable-next-line
    useEffect(() => {
        return () => {
            if (templateWasUse) clearReferralTemplate();
        };
    }, [templateWasUse]);

    const chooseUploadComponent = () => {
        if (useExampleFiles) {
            return (
                <BlockTable
                    keys={[
                        {
                            style: {
                                padding: 0,
                            },
                        },
                    ]}
                    values={[
                        [
                            <div className="inline-elements-container">
                                <SelectOneTable
                                    selectedOption={
                                        selectedExample
                                            ? selectedExample.id
                                            : ""
                                    }
                                    onOptionSelect={handleSelectExample}
                                    selectedFramework={selectedFramework}
                                    currentExamples={
                                        allExamples
                                            ? allExamples[selectedFramework]
                                            : []
                                    }
                                />
                            </div>,
                        ],
                    ]}
                />
            );
        }

        if (usePreviousFiles) {
            return (
                <UserInstruction
                    id="use-previous-files-tip"
                    instruction="The files from the previous job will be propagated."
                />
            );
        }

        return (
            <UploadNewApplicationFiles
                fileError={fileError}
                framework={selectedFramework}
                handleNotebookFileInputChange={handleNotebookFileInputChange}
                handleRequirementsFileInputChange={
                    handleRequirementsFileInputChange
                }
                handleZippedFileInputChange={handleZippedFileInputChange}
                templateFiles={defaultFiles}
            />
        );
    };

    function canCreateApplication() {
        const isCpuEnabled = cpuToRamMapping[selectedCpu]?.enabled;
        const isRamEnabled = selectedRamList?.find(
            ([ram, enabled]) => ram === selectedRam && enabled
        );
        const isGpuEnabled = gpuVal[selectedGpu]?.enabled;

        let isStorageValid = false;
        if (storageEnabled) {
            if (selectedStorage) {
                isStorageValid = true;
            }
        } else {
            isStorageValid = true;
        }

        return (
            selectedCpu &&
            selectedRam &&
            selectedGpu &&
            isCpuEnabled &&
            isGpuEnabled &&
            isRamEnabled &&
            isStorageValid &&
            authCredentialsValid &&
            projectNameValid &&
            !checkFormErrors(false) &&
            !applicationCreated
        );
    }

    const actions = isRedeploying
        ? [
              <ActionButton
                  key="download-previous-files-button"
                  variant="outlined"
                  id="download-previous-files-button"
                  onClick={() => handleRetrievePreviousFiles(project?.id)}
                  size="small"
                  startIcon={<CloudDownload />}
              >
                  Retrieve Previous Files
              </ActionButton>,
          ]
        : [];

    const generalDescription = isRedeploying
        ? "General settings: add labels"
        : "General settings: set project name, and labels";

    // the project selection dropdown is able to switch the URL, so must get the referredProjectId from the URL
    useEffect(() => {
        const refProjectId = new URLSearchParams(location.search).get(
            "referredProjectId"
        );
        if (refProjectId) {
            setReferredProjectId(refProjectId);
        } else {
            setReferredProjectId(null);
            useReferalExample();
        }
    }, [location.search]);

    return (
        <form
            id="create-application"
            data-testid="create-application"
            style={{ marginTop: 20 }}
        >
            {isDownloadingTemplate && (
                <Loader
                    inner={
                        <>
                            <Typography
                                variant="h6"
                                gutterBottom
                                style={{
                                    fontSize: "1rem",
                                }}
                            >
                                Loading template
                            </Typography>
                            {!tourInProgress && (
                                <ActionButton
                                    className="cancelBtn"
                                    variant="text"
                                    onClick={() => setCancelSetTemplate(true)}
                                    size="small"
                                >
                                    Cancel
                                </ActionButton>
                            )}
                        </>
                    }
                />
            )}
            {!useExampleFiles && uploadProgress && (
                <UploadProgress
                    title="Uploading your files. Please keep this tab open."
                    progress={uploadProgress.progress}
                    status={uploadProgress.status}
                />
            )}
            <Block open={!isRedeploying} id="general-header">
                <BlockHeader title="General" description={generalDescription} />
                <BlockContent>
                    <SelectProjectController
                        options={projects?.map((p) => p.name ?? p.id) || []}
                        onChange={(e) => {
                            const targetProject = projects.find((p) =>
                                [p.id, p.name].includes(e.target.value)
                            );
                            setProject(targetProject);
                            setLatestJobDetails(targetProject);
                            if (e.target.value === NEW_PROJECT_ITEM.id) {
                                navigate(`/applications/create`);
                            } else {
                                navigate(
                                    `/applications/create?referredProjectId=${targetProject.id}`
                                );
                            }
                        }}
                        disabled={isRedeploying}
                        defaultValue={project?.name || project?.id}
                    />

                    <SetProjectNameController
                        customName={customProjectName}
                        onChange={(name, valid) => {
                            setProjectName(name);
                            setProjectNameValid(valid);
                        }}
                    />

                    <SetProjectLabelsController
                        labels={labels}
                        onChange={(newLabels) => {
                            setLabels(newLabels);
                        }}
                    />
                </BlockContent>
            </Block>

            <Block>
                <BlockHeader
                    title="Framework"
                    description="The framework used by your application"
                />
                {isLoadingProject ? (
                    <Box margin={20}>
                        <LinearProgress />
                    </Box>
                ) : (
                    <SelectProjectFrameworkGridController
                        selectedOption={selectedFramework}
                        onSelect={handleSelectFramework}
                        preSelectedId={referredProjectType}
                    />
                )}
            </Block>

            <Block>
                <BlockHeader
                    title="Source code"
                    description="Create application by either uploading your own files or using our example"
                    actions={actions}
                />
                <BlockContent>
                    <BlockTable
                        keys={[
                            {
                                style: {
                                    padding: 0,
                                },
                            },
                        ]}
                        values={[
                            [
                                <div
                                    style={{
                                        display: "inline-flex",
                                        gap: 50,
                                        width: "100%",
                                    }}
                                >
                                    <BlockToggleButton
                                        title="Upload your files"
                                        id="uploadyourfiles"
                                        onClick={() => {
                                            setUseExampleFiles(false);
                                            setUsePreviousFiles(false);

                                            setNotebookFile();
                                            setRequirementsFile();
                                            setZippedFile();
                                        }}
                                        checked={
                                            !useExampleFiles &&
                                            !usePreviousFiles
                                        }
                                    />

                                    <BlockToggleButton
                                        title="Create from example"
                                        id="createfromexample"
                                        onClick={() => {
                                            setUseExampleFiles(true);
                                            setUsePreviousFiles(false);
                                            setNotebookFile();
                                            setRequirementsFile();
                                            handleSelectFramework(
                                                selectedFramework
                                            );
                                        }}
                                        checked={useExampleFiles}
                                    />

                                    {isRedeploying && (
                                        <BlockToggleButton
                                            title="Use previous files"
                                            id="use-previous-files-input"
                                            onClick={() => {
                                                setUseExampleFiles(false);
                                                setUsePreviousFiles(true);
                                            }}
                                            checked={usePreviousFiles}
                                        />
                                    )}
                                </div>,
                            ],
                        ]}
                    />

                    {chooseUploadComponent()}
                </BlockContent>
            </Block>

            <Block open={secrets.length > 0} id="envVarsBlock">
                <BlockHeader
                    title="Secrets"
                    description="Set application secrets"
                    collapse
                />
                <BlockContent>
                    <SetSecretsController
                        onRemoveKey={(indexToRemove) => {
                            const newSecrets = secrets.filter(
                                (_, index) => index !== indexToRemove
                            );
                            setSecrets(newSecrets);
                        }}
                        featureId="secrets"
                        envVariables={secrets}
                        onSecretUpdate={(key, value, updatedSecrets) => {
                            if (updatedSecrets) {
                                // Assuming updatedSecrets is an entirely new list of secrets with duplicates already handled
                                // Add new key-value pair if key does not exist
                                setSecrets(
                                    updatedSecrets.map((variable) => ({
                                        ...variable,
                                    }))
                                );
                            } else {
                                const index = secrets.findIndex(
                                    (p) => p.key === key
                                );
                                if (index !== -1) {
                                    // Key exists, update value if needed, but primarily checking for duplicates
                                    updateSnackbarStatus({
                                        message:
                                            "Duplicate keys are not allowed.",
                                        severity: "error",
                                    });
                                } else {
                                    // Add new key-value pair if key does not exist
                                    const noEmptySecrets = secrets.slice(
                                        0,
                                        secrets.length - 1
                                    );
                                    const newSecrets = [
                                        ...noEmptySecrets,
                                        { key, value },
                                    ];
                                    setSecrets(newSecrets);
                                }
                            }
                        }}
                        onNewField={() => {
                            const newSecrets = JSON.parse(
                                JSON.stringify(secrets)
                            );

                            const lastPairKeyValue =
                                newSecrets[newSecrets.length - 1];
                            if (
                                secrets.length !== 0 &&
                                lastPairKeyValue.key === "" &&
                                lastPairKeyValue.value === ""
                            ) {
                                updateSnackbarStatus({
                                    message:
                                        "Please fix the existing key value pair",
                                    severity: "error",
                                });
                            } else {
                                setSecrets([
                                    ...newSecrets,
                                    { key: "", value: "" },
                                ]);
                            }
                        }}
                    />
                </BlockContent>
            </Block>

            <Block open={Boolean(true)}>
                <BlockHeader
                    title="Advanced"
                    description="Hardware and security settings"
                />
                <BlockContent>
                    <h2 style={{ marginBottom: 15 }}>Hardware</h2>

                    <BlockTable
                        keys={[{ style: { width: "30%", padding: 0 } }]}
                        values={[
                            [
                                <div
                                    style={{
                                        display: "inline-flex",
                                        gap: 50,
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "50%",
                                        }}
                                    >
                                        <SelectCPUController
                                            featureId="selectNumberOfCPUs"
                                            value={selectedCpu}
                                            cpuOptions={Object.keys(
                                                cpuToRamMapping
                                            )
                                                .sort(
                                                    (a, b) =>
                                                        Number(a) - Number(b)
                                                )
                                                .map((key) => [
                                                    cpuToRamMapping[key].value,
                                                    cpuToRamMapping[key]
                                                        .enabled,
                                                    cpuToRamMapping[key]
                                                        .pricePerHour,
                                                ])}
                                            onChange={(value) => {
                                                setSelectedCpu(value);
                                            }}
                                        />
                                    </div>

                                    <div
                                        style={{
                                            width: "50%",
                                        }}
                                    >
                                        <SelectRAMController
                                            featureId="selectRAM"
                                            value={selectedRam}
                                            ramOptions={selectedRamList}
                                            onChange={(value) => {
                                                setSelectedRam(value);
                                            }}
                                        />
                                    </div>

                                    <div
                                        style={{
                                            width: "50%",
                                        }}
                                    >
                                        <div
                                            style={{
                                                margin: "auto",
                                                marginBottom: 5,
                                            }}
                                        >
                                            <SelectGPUController
                                                featureId="selectNumberOfGPUs"
                                                value={selectedGpu}
                                                gpuOptions={Object.keys(
                                                    gpuVal
                                                ).map((key) => [
                                                    gpuVal[key].value,
                                                    gpuVal[key].enabled,
                                                    gpuVal[key].pricePerHour,
                                                ])}
                                                onChange={(value) => {
                                                    setSelectedGpu(value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>,
                            ],
                        ]}
                    />

                    <div
                        style={{
                            width: "50%",
                        }}
                    >
                        <SetStorageController
                            onToggle={setStorageEnabled}
                            storageEnabled={storageEnabled}
                            disabled={
                                !storageEnabled ||
                                forceDisableStorageBecauseForbidden
                            }
                            value={selectedStorage}
                            previousStorageValue={selectedStorage}
                            onChange={(storage) => {
                                setSelectedStorage(storage);
                            }}
                        />
                    </div>

                    <AuthenticationController
                        disabled={!authEnabled}
                        authEnabled={authEnabled}
                        onCredentialsUpdate={(credentials) => {
                            setAuthCredentialsValid(credentials.areValid);
                            setCarryOverAuth(credentials.carryOverAuth);

                            let newAuthentication;
                            if (
                                credentials.useAuth &&
                                !credentials.carryOverAuth
                            ) {
                                const { password, username } = credentials;
                                newAuthentication = { username, password };
                            }
                            setAuthentication(newAuthentication);
                        }}
                    />

                    <DeploymentCostRenderer
                        cpu={selectedCpu}
                        ram={selectedRam}
                        gpu={selectedGpu}
                        storage={selectedStorage}
                    />
                </BlockContent>
            </Block>

            <Block border={0}>
                <BlockContent style={{ padding: 0 }}>
                    <Box
                        sx={{
                            display: "flex",
                            textAlign: "right",
                            gap: 1,
                            flexDirection: "row-reverse",
                            width: "100%",
                        }}
                    >
                        <ActionButton
                            id="createNewApplicationButton"
                            testid="create-new-application-button"
                            variant="contained"
                            color="primary"
                            onClick={createApplicationClick}
                            disabled={!canCreateApplication()}
                            disabledOptions={
                                applicationCreated
                                    ? {
                                          startIcon: (
                                              <CircularProgress
                                                  size={10}
                                                  color="neutral"
                                                  style={{ marginLeft: 12 }}
                                              />
                                          ),
                                          text: referredProjectId
                                              ? "Updating... "
                                              : "Creating...",
                                      }
                                    : {}
                            }
                        >
                            {referredProjectId ? "Update" : "Create"}
                        </ActionButton>

                        <ActionButton
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                navigate("/applications");
                            }}
                            hide={false}
                        >
                            Cancel
                        </ActionButton>
                    </Box>
                </BlockContent>
            </Block>
        </form>
    );
}

export default CreateApplication;
